<template>
  <transition name="slide">
    <CCard>
      <CCardBody>
        <CButton color="primary" @click="create()"
          ><CIcon name="cil-plus" /> Create New Partner</CButton
        >
        <br />
        <br />
        <br />

        <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>

        <CDataTable
          :items="items"
          :fields="fields"
          :items-per-page="50"
          hover
          pagination
        >
          <template #status="{ item }">
            <td>
              <CBadge :color="getBadge(item.active)">
                {{ item.active == true ? "Active" : "Inactive" }}
              </CBadge>
            </td>
          </template>
          <template #name="{ item }">
            <td style="width: 40%">
              <strong>{{ item.name }}</strong>
            </td>
          </template>
          <template #EDIT="{ item }">
            <td>
              <CButton color="primary" @click="edit(item.id)"
                ><CIcon name="cil-pencil" /> Edit</CButton
              >
            </td>
          </template>
          <template #DELETE="{ item }">
            <td>
              <CButton
                color="danger"
                @click="
                  warningModal = true;
                  partner = item;
                "
                ><CIcon name="cil-trash" /> Delete</CButton
              >
            </td>
          </template>
        </CDataTable>
        <CModal
          v-model="partner.id"
          title="Are you sure you want to delete this partner?"
          color="danger"
          :show.sync="warningModal"
          @update:show="closeModal"
        >
          <CRow>
            <CCol col="6">
              <CInput label="Name" v-model="partner.name" disabled />
            </CCol>
            <CCol col="12">
              <CInput label="Time Zone" v-model="partner.timeZone" disabled />
            </CCol>
            <CCol col="6">
              <div><label>Logo</label></div>
              <img
                :src="partner.logo"
                class="img-thumbnail"
                style="max-height: 200px"
              />
            </CCol>
          </CRow>
        </CModal>
      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "Partners",

  data: () => {
    return {
      // use uuuid
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      fields: ["status", "name", "timeZone", "EDIT", "DELETE"],

      warningModal: false,
      partner: [],

      // Alert
      alertType: "danger",
      message: null,

      // List
      items: [],
    };
  },
  computed: {},
  methods: {
    getBadge(status) {
      switch (status) {
        case true:
          return "success";
        case false:
          return "secondary";
        default:
          return "primary";
      }
    },

    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.partner.id);
      }
    },

    edit(id) {
      this.$router.push({ path: `partners/create/${id}` });
    },
    delete(id) {
      let self = this;
      axios
        .delete(this.$apiAdress + "/v1/Partner/" + id)

        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted partner.";
          self.getList();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    create() {
      this.$router.push({ path: "/partners/create/" + this.emptyGuid });
    },

    getList() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Partner")
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.getList();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
